import { parseDate } from "chrono-node";
import { ReactElement } from "react";
import { DateTimeFormatHandler } from "../../../hooks/useDateTimeFormatter";
import BulbIcon from "../../../img/bulb-icon.svg";
import CoffeeIcon from "../../../img/coffee.svg";
import CompassIcon from "../../../img/compass-icon.svg";
import ElectricIcon from "../../../img/lightning-bolt.svg";
import ChatIcon from "../../../img/message-square.svg";
import SendIcon from "../../../img/send-icon.svg";
import SmileIcon from "../../../img/smile.svg";
import SparkleIcon from "../../../img/sparkle-icon.svg";
import StarIcon from "../../../img/star-outline.svg";
import SunIcon from "../../../img/sun.svg";
import {
  CreateSchedulingLinkRequest,
  PatchSchedulingLinkRequest,
  SchedulingLink,
  SchedulingLinkIconType,
  TimePolicyType,
} from "../../../reclaim-api/scheduling-links/SchedulingLinks";
import { MILLISECONDS_PER_DAY, MILLISECONDS_PER_MINUTE } from "../../../utils/dates";
import { StrongSelectItem } from "../StrongSelect";
import { SchedulingLinkFormFieldValues } from "./SchedulingLinkForm.types";

export const defaultFieldValues = (): SchedulingLinkFormFieldValues => ({
  title: "",
  description: "",
  timePolicyType: "MEETING",
  oneOffPolicy: undefined,
  durations: [],
  daysIntoFuture: MILLISECONDS_PER_DAY * 60,
  startDate: undefined,
  endDate: undefined,
  priority: "DEFAULT",
  conferenceType: "NONE",
  iconType: "LIGHT",
  slug: "",
  location: "",
});

export const schedulingLinkToFieldValues = (
  format: DateTimeFormatHandler,
  link: SchedulingLink
): SchedulingLinkFormFieldValues => ({
  title: link.title || "",
  iconType: link.iconType,
  description: link.description || "",
  timePolicyType: link.timePolicyType,
  oneOffPolicy: link.oneOffPolicy,
  durations: link.durations.sort((a, b) => a - b).map((l) => l * MILLISECONDS_PER_MINUTE),
  daysIntoFuture: !!link.daysIntoFuture ? link.daysIntoFuture * MILLISECONDS_PER_DAY : undefined,
  startDate: !!link.startDate ? format(link.startDate, "DATE_YEAR_DISPLAY_FORMAT") : undefined,
  endDate: !!link.endDate ? format(link.endDate, "DATE_YEAR_DISPLAY_FORMAT") : undefined,
  conferenceType: link.conferenceType || "NONE",
  priority: link.priority,
  slug: link.slug || "",
  location: link.location || "",
});

export const fieldValuesToCreateSchedulingLinkRequest = (
  values: SchedulingLinkFormFieldValues
): CreateSchedulingLinkRequest => ({
  title: values.title,
  description: values.description,
  enabled: true,
  timePolicyType: values.timePolicyType,
  oneOffPolicy: values.oneOffPolicy || undefined,
  durations: values.durations.map((l) => l / MILLISECONDS_PER_MINUTE),
  daysIntoFuture: !!values.daysIntoFuture ? values.daysIntoFuture / MILLISECONDS_PER_DAY : null,
  startDate: !!values.startDate ? parseDate(values.startDate) : undefined,
  endDate: !!values.endDate ? parseDate(values.endDate) : undefined,
  priority: values.priority,
  conferenceType: values.conferenceType === "NONE" ? undefined : values.conferenceType,
  iconType: values.iconType,
  slug: values.slug,
  location: values.location,
});

export const fieldValuesToPatchSchedulingLinkRequest = (
  values: SchedulingLinkFormFieldValues
): PatchSchedulingLinkRequest => ({
  title: values.title,
  description: values.description || null,
  enabled: true,
  timePolicyType: values.timePolicyType,
  oneOffPolicy: values.oneOffPolicy || undefined,
  durations: values.durations.map((l) => l / MILLISECONDS_PER_MINUTE),
  daysIntoFuture: !!values.daysIntoFuture ? values.daysIntoFuture / MILLISECONDS_PER_DAY : null,
  startDate: !!values.startDate ? parseDate(values.startDate) : null,
  endDate: !!values.endDate ? parseDate(values.endDate) : null,
  priority: values.priority,
  conferenceType: values.conferenceType === "NONE" ? null : values.conferenceType,
  iconType: values.iconType,
  slug: values.slug,
  location: values.location,
});

export const iconElementMap = (): { [key in SchedulingLinkIconType]: { position: number; icon: ReactElement } } => ({
  LIGHT: { position: 0, icon: <BulbIcon /> },
  COFFEE: { position: 1, icon: <CoffeeIcon /> },
  BOLT: { position: 2, icon: <ElectricIcon /> },
  COMMENT: { position: 3, icon: <ChatIcon /> },
  STAR: { position: 4, icon: <StarIcon /> },
  AIRPLANE: { position: 5, icon: <SendIcon /> },
  TWINKLE: { position: 6, icon: <SparkleIcon /> },
  COMPASS: { position: 7, icon: <CompassIcon /> },
  SUN: { position: 8, icon: <SunIcon /> },
  SMILE: { position: 9, icon: <SmileIcon /> },
});

export const timePolicyTypeSelectOptions = (): StrongSelectItem<TimePolicyType>[] => [
  { value: "MEETING", label: "Meeting" },
  { value: "WORK", label: "Working" },
  { value: "PERSONAL", label: "Personal" },
  { value: "ONE_OFF", label: "Custom" },
];
